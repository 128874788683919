<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <div v-if="pl == 3">
      <div class="heds">授权操作</div>
      <div class="mian">手机尾号为{{phone}}的用户成为您的推荐客户需要授权，请点击下列按钮通过。</div>
      <van-button class="squan" type="danger" @click="bangding">确认授权</van-button>
    </div>
    <div v-else class="noqx">您没有操作权限</div>
  </div>
</template>
<script>
import couponModel from "../../api/coupon";
export default {
  data() {
    return {
      tokerId: this.$route.query.tokerId,
      offid: this.$route.query.offid,
      carNo: this.$route.query.carNo,
      pl: this.$route.query.pl,
      phone: this.$route.query.phone,
      gid: this.$route.query.gid
    };
  },
  methods: {
    bangding() {
      couponModel
        .tokerbinding({
          tokerId: this.tokerId,
          gid: this.gid,
          carNo: this.carNo,
          offId:this.offid
        })
        .then(res => {
          if (res.code == 200) {
            this.$toast.success("授权成功！");
          }
        });
    }
  }
};
</script>
<style lang="less" scoped>
.imgs {
  width: 100%;
  display: block;
}
.heds {
  font-size: 18px;
  text-align: center;
  line-height: 50px;
}
.mian {
  font-size: 16px;
  width: 70%;
  margin: auto;
  margin-top: 10px;
  color: #666;
  text-indent: 2em;
}
.squan {
  width: 120px;
  height: 35px;
  display: block;
  margin: auto;
  margin-top: 50px;
}
.noqx {
  color: red;
  font-size: 16px;
  padding-top: 250px;
  text-align: center;
}
</style>