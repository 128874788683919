import request from '@/libs/request'

const coupon = {
    getActivityList(params) {
        return request({
            url: '/vehicle/activity/getActivityList',
            method: 'get',
            params
        })
    },
 
    takeActivity(data) {
        return request({
            url: '/vehicle/activity/takeActivity',
            method: 'post',
            data
        })
    },
    tokerbinding(params) {//绑定老用户上线并记录
        return request({
            url: '/vehicle/toker/binding',
            method: 'GET',
            params
        })
    },
}
export default coupon
